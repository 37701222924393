// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-content-page-js": () => import("/opt/build/repo/src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-area-page-js": () => import("/opt/build/repo/src/templates/area-page.js" /* webpackChunkName: "component---src-templates-area-page-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-booking-page-js": () => import("/opt/build/repo/src/templates/booking-page.js" /* webpackChunkName: "component---src-templates-booking-page-js" */),
  "component---src-templates-faq-page-js": () => import("/opt/build/repo/src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-location-page-js": () => import("/opt/build/repo/src/templates/location-page.js" /* webpackChunkName: "component---src-templates-location-page-js" */),
  "component---src-templates-lodge-page-js": () => import("/opt/build/repo/src/templates/lodge-page.js" /* webpackChunkName: "component---src-templates-lodge-page-js" */),
  "component---src-templates-park-page-js": () => import("/opt/build/repo/src/templates/park-page.js" /* webpackChunkName: "component---src-templates-park-page-js" */),
  "component---src-templates-product-page-js": () => import("/opt/build/repo/src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/build/repo/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-booking-booking-form-js": () => import("/opt/build/repo/src/pages/booking/booking-form.js" /* webpackChunkName: "component---src-pages-booking-booking-form-js" */),
  "component---src-pages-booking-thanks-js": () => import("/opt/build/repo/src/pages/booking/thanks.js" /* webpackChunkName: "component---src-pages-booking-thanks-js" */),
  "component---src-pages-contact-examples-js": () => import("/opt/build/repo/src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("/opt/build/repo/src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("/opt/build/repo/src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-tags-index-js": () => import("/opt/build/repo/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-testing-js": () => import("/opt/build/repo/src/pages/testing.js" /* webpackChunkName: "component---src-pages-testing-js" */)
}

